import React from 'react';

const ModalEmail = (props) => {
    return (
        <>
            <div className="modal-header">
          <div className="modal-title h4"> </div>
          <button
            type="button"
            onClick={props.closeModal}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>

        <h4>enter your email</h4>
        <input 
          className="swa-input"
          value={props.account}
          onChange={(e) => props.setAccount(e.target.value)}
          type="text"
          
        />

        <p> {props.msgState} </p>
        <button
          className="buyNow--btn"
          style={{backgroundColor : "#f57059", color : "#fff"}}
          onClick={props.postGift}
          disabled={props.lodding ? true : false}
        >
           <span className={props.lodding ? "loadingdiv spinner" : "loadingdiv"}></span>
                  <span>request</span>
                  
        </button> 
        </>
    );
}

export default ModalEmail;
