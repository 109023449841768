import React from "react";

const CardOfferOgads = ({ handleShowOgads , img }) => {
  return (
    <span onClick={() => handleShowOgads()} className="offer--wall--item">
      <img src={img} alt="" />
      <button className="bonus--btn">+10% Bonus</button>
    </span>
  );
};

export default CardOfferOgads;
