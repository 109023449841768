import { useState } from "react";

export function useModalYoumi(){
    const [modalIsOpenYoumi, setIsOpenYoumi] =  useState(false);
     
      function closeModalYoumi() {
        setIsOpenYoumi(false);
      }

    return {
        modalIsOpenYoumi , closeModalYoumi ,setIsOpenYoumi
    }
}