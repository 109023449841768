import React from 'react';
import CardAdgemApi from './CardAdgemApi';
import CardOfferApi from './CardOfferApi';
import HeadContainer from './HeadContainer';
import Lodaing from './Lodaing';

const OfferApi = (props) => {
 
    return (
        <div className="earn--featured--wrap">
          <div className="container">
            <HeadContainer title="Featured offers" body="The most frequently completed tasks by people from your country
                recently." />
            

            <div className="row featured--offer--row">
            

              {props.loading ? (

                 <Lodaing />
                ) : (
                  <div className="row offer--wall--row">
                    {/* offerApi */}
                    {props.offerApi !== [] ? (
                      <CardOfferApi
                      offerApi={props.offerApi}
                      showModalApi={props.showModalApi}
                    />
                    ) : (
                      <></>
                    )}
                    {/* adgem */}
                     
                    {/* {props.adgemApi !== [] ? (
                      <CardAdgemApi
                      adgemApi={props.adgemApi}
                      showModalApi={props.showModalAdgemApi}
                    />
                    ) : (
                      <></>
                    )} */}

                     
                    

                  </div>
                )}
 
            </div>
          </div>
        </div>
    );
}

export default OfferApi;
