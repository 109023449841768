import React from 'react';

const Section1 = () => {
    return (
        <div className="hero--wrap">
        <div className="container position--relative">
          <div className="hero--shape--1">
            <img src="assets/image/shape/hero-bottom.png" alt="" />
          </div>

          <div className="row hero--row--bg position--relative">
            <div className="hero--left">
              <small>
                <span>The best</span> website according to users
              </small>
              <h2>
                Earn money <br />
                redeem gift cards
              </h2>
              <p>
                Earn $1.00 per 5-10 minute offer and cashout <br />
                instantly in Crypto, PayPal, Gift cards, and more.
              </p>
              <div className="hero--btn--group">
                <a href="#" className="btn btn--more btn--seemore">
                  See more
                  <span>
                    <img
                      src="assets/image/icon/arrow-right.svg"
                      alt="see more"
                    />
                  </span>
                </a>
                <div className="player--btn">
                  <img src="assets/image/shape/play.png" alt="player" />
                </div>
              </div>
              <div className="brand--cashout">
                <img
                  src="assets/image/brand/hero-brand-grp.png"
                  alt="brand payment"
                  className="img--fluid"
                />
              </div>
            </div>

            <div className="hero--right">
              <img
                src="assets/image/img/vector_slider.png"
                alt=""
                className="img--fluid"
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Section1;
