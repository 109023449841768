import { localeData } from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RankCard1 from "../components/cards/RankCard1";
import RankCard2 from "../components/cards/RankCard2";
import RankCard3 from "../components/cards/RankCard3";
import HourRank from "../components/HourRank";
import RankTable from "../components/tables/RankTable";
import { checkIp } from "../network/earnNet";
import { getRank } from "../network/LeaderboardNet";

const LeaderboardPage = () => {
  const [rank, setRank] = useState([]);

  useEffect(() => {
    getRank()
      .then((response) => {
        var data = response.data.rank;  
        function compareNumbers(x, y) {
          return x.score_cur - y.score_cur;
        }
        data = data.sort(compareNumbers).reverse(); // [1, 5, 40, 200] 
        setRank(data);
        if(response.data.use_ip_used){ 
            setTimeout(() => {
              checkIp()
            }, response.data.timer * 1000);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  return (
    <div style={{ minHeight: " 400px" }}>
      <div className="leaderboard--wrap position--relative">
        <div className="leaderboard--body--shape--1">
          <img src="assets/image/shape/header-bottom.png" alt="" />
        </div>

        <div className="container">
          <div className="row justify--center leaderboard--users--row">
            {rank.slice(1, 2).map((ran, index) => (
              <RankCard2 key={index} ran={ran} />
            ))}
            {rank.slice(0, 1).map((ran, index) => (
              <RankCard1 key={index} ran={ran} />
            ))}
            {rank.slice(2, 3).map((ran, index) => (
              <RankCard3 key={index} ran={ran} />
            ))}
          </div>

          <div className="leaderboard--rank--bar">
            <HourRank />
            {/* <div className="leaderboard--rank--box">
              You earned
              <span className="today--coins--wrap">
                <span className="today--coins">0</span> coins today
              </span>
              and are ranked - out of
              <span className="out--of--users">11549</span> users
            </div> */}
          </div>

          <RankTable rank={rank} />
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPage;
