import React, { useEffect, useRef, useState } from "react";
import { getStyle } from "../functions.js/functions";
import { supabase } from "../supabase/supabaseClient";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import moment from "moment/moment";
import Modal from "react-modal";
import { getDetailsUser } from "../network/notUser";
import Chart from "react-apexcharts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function UpperBar() {
  const [dataChat, setDataChat] = useState([]);
  const [daysS, setDays] = useState([]);
  let days = [];
  let today = moment();
  const [loading, setLoading] = useState(false);
  const [dUser, setDUser] = useState([]);
  const messagesEndRef = useRef(null);
  const [openDetailsUser, setIsOpenDetailsUser] = React.useState(false);

  supabase
    .from("events")
    .on("INSERT", (message) => { 
      setDataChat((oldArray) => [  message.new,...oldArray]); 
     
    })
    .subscribe();

  const getDataSupabase = async () => {
    try {
      setLoading(true);

      let { data, error, status } = await supabase
        .from("events")
        .select(`*`)
        .order("id", {
          ascending: false,
        })
        .limit(20);

      if (error && status !== 406) {
        throw error;
      }

      if (data) { 
        setDataChat(data);  
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    var i = 0;
    for (i = 0; i < 6; i++) {
      let day = today.subtract(i, "days");
      days.push(day.format("dddd"));
    }
    setDays(days.reverse());

    getDataSupabase();
  }, []);

  const notify = (msg) => toast(msg);

  function showDetailsUSer(userId) {
    getDetailsUser(userId)
      .then(function (response) { 
        if (response.data.status === 0) {
          notify(response.data.message);
        } else {
          setDUser(response.data);
          openModalDetailsUser();
        }
      })
      .catch(function (error) { 
      });
  }
  function openModalDetailsUser() { 
    setIsOpenDetailsUser(true);
  }

  function afterDetailsUser() {
    // references are now sync'd and can be accessed.
  }

  function closeDetailsUser() {
    setIsOpenDetailsUser(false);
  }
  const customStyles = {
    content: {
      position: "absolute", 
      padding: "20px", 
      top: "5%", 
      backgroundColor: "#191e3c",
      border: "0px solid",
      overflowY: "scroll",
      zIndex: "9999",
    },
  };

  const seriesEarn = [
    //data on the y-axis
    {
      name: "earn",
      data: dUser.sumEarnDay,
    },
  ];
  const seriesOffer = [
    //data on the y-axis
    {
      name: "Offers",
      data: dUser.countOfferDay,
    },
  ];

  const optionsOffer = {
    //data on the x-axis
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "complate offer by week",
      align: "left",
      style: {
        color: "#fff",
      },
    },
    colors: ["#f57059"],
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: daysS,
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
  };

  const optionsEarn = {
    //data on the x-axis
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "earn by week",
      align: "left",
      style: {
        color: "#fff",
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: daysS,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={openDetailsUser}
        onAfterOpen={afterDetailsUser}
        onRequestClose={closeDetailsUser}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
        className="upbarPopup"
      >
        {dUser.name ? (
          <>
            <div className="modal-header" style={{ paddingBottom: "10px" }}>
              <div className="modal-title h4"> </div>
              <button
                type="button"
                onClick={closeDetailsUser}
                className="cancel--btn"
                aria-label="Close"
              >
                <img src="assets/image/cancel.png" alt="" />
              </button>
            </div>
            <div className="loader"></div>
            <div className="visibility">
              <div className="statistics--header">
                <div className="statistics--user">
                  <div className="statistics--user--img statistics--user--img1">
                     
                      
                                <img src={'../avatars/'+dUser.avatar} alt="" />
                               
                  </div>
                  <div className="statistics--user--name statistics--user--name1">
                    {dUser.name}
                  </div>
                </div>
                <div className="required--xp">
                  <span className="statistics_points_xp"></span> XP Earned. 60 XP
                  required for next level up.
                </div>
              </div>

              <div className="statistics--summery">
                <div className="statistics--sum--itm">
                  <div className="stat--sum--icon st--sum--icon1">
                    <img src="assets/image/icon/successful-update.png" alt="" />
                  </div>
                  <div className="statistics--sum--info">
                    <p>Completed offers</p>
                    <h6 className="statistics--ffers">{dUser.complatedOffers}</h6>
                  </div>
                </div>

                <div className="statistics--sum--itm">
                  <div className="stat--sum--icon st--sum--icon2">
                    <img src="assets/image/icon/coins.png" alt="" />
                  </div>
                  <div className="statistics--sum--info">
                    <p>Coins earned</p>
                    <p className="statistics--points_bar">{dUser.coinsEarned}</p>
                  </div>
                </div>

                <div className="statistics--sum--itm">
                  <div className="stat--sum--icon st--sum--icon3">
                    <img src="assets/image/icon/successful-update.png" alt="" />
                  </div>
                  <div className="statistics--sum--info">
                    <p>Users referred</p>
                    <h6 className="statistics--referred">{dUser.countReferred}</h6>
                  </div>
                </div>
              </div>
              <div className="statistics--chat">
                <h5>Recent earnings (7 Days)</h5>
                <small>Coins for week</small>

                <div>
                  <Chart
                    options={optionsEarn}
                    series={seriesEarn}
                    type="line"
                    height="200"
                  />
                  <Chart
                    options={optionsOffer}
                    series={seriesOffer}
                    type="line"
                    height="200"
                  />
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <></>
        )}
      </Modal>

      <section className="topEarnFeed d--flex align--center position--relative">
        <div className="topfeed--shape">
          <img src="assets/image/shape/header-bottom.png" alt="" />
        </div>

        <div className="container">
          <div
            className="earnFeedCarousel"
            style={{ display: "flex" }}
          >
            {dataChat !== []
              ? dataChat.map((item, index) => (
                  <Tooltip
                    key={index}
                    // options
                    eventOff="click"
                    globalEventOff="click"
                    overridePosition = {{left:100, top: 500}}
                    html={
                      <div
                        className="tippy-box"
                        data-state="visible"
                        tabIndex="-1"
                        data-animation="fade"
                        role="tooltip"
                        data-placement="top"
                        data-escaped=""
                        style={{ width: "250px" ,marginTop:"100px"}}
                        >
                        <div className="tippy-content" data-state="visible">
                          <div className="Tooltip">
                            {item.type === "get" ? (
                              <>
                                <span className="span_tooltip">
                                  username :{" "}
                                </span>{" "}
                                {item.username.slice(0, 5)} <br />
                                <span className="span_tooltip">
                                  {" "}
                                  withdrawal :{" "}
                                </span>{" "}
                                {item.company} <br />
                                <span className="span_tooltip">
                                Coins :{" "}
                                </span>{" "}
                                {item.points} c <br />{" "}
                              </>
                            ) : (
                              <>
                                <span className="span_tooltip">
                                  offername :{" "}
                                </span>{" "}
                                {item.name_of_r ?? item.name_of_r} <br />
                                <span className="span_tooltip">
                                  {" "}
                                  username :{" "}
                                </span>{" "}
                                {item.username} <br />
                                <span className="span_tooltip">
                                Coins :{" "}
                                </span>{" "}
                                {item.points} c <br />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                    position="bottom"
                    trigger="mouseenter"
                  >
                    <div
                      className="earnFeedItem"
                      data-id="0"
                      onClick={() => showDetailsUSer(item.userid)}
                    >
                      
                       <div className="account--img">
                        <img src={'../avatars/'+item.avatar}  alt="" /></div>
                      <div className="ef--about">
                        {item.type === "offer" ? (
                          <>
                            <span>{item.company}</span>
                            <span
                              className="earn--name"
                              style={{ display: "block" }}
                            >
                              {item.name_of_r.slice(0, 15)}
                            </span>
                          </>
                        ) : (
                          <>
                            <span>WITHDRAWAL</span>
                            <span className="earn--name">{item.company}</span>
                          </>
                        )}
                      </div>
                      <div className="ef--coins">
                        <span>{item.points}</span>
                      </div>
                    </div>
                  </Tooltip>
                ))
              : "loading..."}
          </div>
        </div>
      </section>
    </>
  );
}

export default UpperBar;
