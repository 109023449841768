import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { newBalanceAction, setLogout } from "../redux/actions/userActions";
import Chat from "./chat";
import MobileNav from "./mobileNav";
import { supabase } from "../supabase/supabaseClient";

function Header() {
  const logged = useSelector((state) => state.userReducer.logged);
  const name = useSelector((state) => state.userReducer.name);
  const img_user = useSelector((state) => state.userReducer.avatar);
  const userId = useSelector((state) => state.userReducer.userId);
  const balance = useSelector((state) => state.userReducer.balance);

  const dispatch = useDispatch();

  /////////// supabase

  supabase
    .from("users:userid=eq." + userId)
    .on("*", (message) => {
      dispatch(newBalanceAction(message.new.balance));
    })
    .subscribe();
  //////////
  const logoutNow = () => {
    dispatch(setLogout());
  };

  const removeModal = () => {
    const mlAuto = document.querySelector(".ml--auto");
    const backdrop = document.querySelector(".backdrop");
    const logo = document.querySelector(".logo");

    backdrop.classList.remove("db--active");
    logo.classList.remove("db--active");
    mlAuto.classList.remove("db--active");
  };
  return (
    <>
      <Chat />
      <MobileNav />

      <header className="header">
        <div className="container">
          <nav className="nav--wrp d--flex align--center">
            <div className="menu--toggle--handler">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <NavLink
              onClick={removeModal}
              className="logo header--logo d--flex align--center"
              exact="true"
              to="/"
            >
              <img src="assets/image/icon/logo_cash120.png" alt="logo" />
              {/* <span>CLASHLY</span> */}
            </NavLink>

            <ul className="nav d--flex align--center ml--auto">
              <li onClick={removeModal}>
                <NavLink
                  className={(Earn) =>
                    Earn.isActive ? " nav-link active" : "nav-link"
                  }
                  to="/earn"
                >
                  <img src="assets/image/icon/earn.svg" alt="earn" />
                  <span>Earn</span>
                </NavLink>
              </li>
              <li onClick={removeModal}>
                <NavLink
                  className={(Cashout) =>
                    Cashout.isActive ? " nav-link active" : "nav-link"
                  }
                  to="/cashout"
                >
                  <img src="assets/image/icon/cashout.svg" alt="cashout" />
                  <span>Cashout</span>
                </NavLink>
              </li>
              <li onClick={removeModal}>
                <NavLink
                  className={(leaderboard) =>
                    leaderboard.isActive ? " nav-link active" : "nav-link"
                  }
                  to="/leaderboard"
                >
                  <img
                    src="assets/image/icon/leader_board.svg"
                    alt="leaderboard"
                  />
                  <span>Leaderboard</span>
                </NavLink>
              </li>
              <li onClick={removeModal}>
                <NavLink
                  className={(Rewards) =>
                    Rewards.isActive ? " nav-link active" : "nav-link"
                  }
                  to="/rewards"
                >
                  <img src="assets/image/icon/rewards.png" alt="Rewards" />
                  <span>Rewards</span>
                </NavLink>
              </li>
            </ul>

            <div className="nav--right">
              {logged ? (
                <>
                  <div className="nav--right account position--relative">
                    <div className="user--box">
                      <div className="account--dropdown">
                        <a className="user--dropdown--toggle" href="#">
                          <img src="assets/image/icon/arrow-down.png" alt="" />
                        </a>
                        <ul className="user--dropdown">
                          <li>
                            <NavLink
                              className={(Profile) =>
                                Profile.isActive ? " active" : " "
                              }
                              to="/profile"
                            >
                              Profile
                            </NavLink>
                          </li>
                          <li>
                            {" "}
                            <a onClick={logoutNow}>Logout</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="account--info--wrp">
                        <div className="account--img">
                          <img src={"../avatars/" + img_user} alt="" />
                        </div>
                        <div className="account--info">
                          <div className="account--name">{name}</div>
                          <div className="coin--dropdown">
                            <div className="account--coin--itm">
                              <img src="assets/image/icon/money.png" alt="" />
                            </div>
                            <h6>{balance}</h6>
                            <a href="#" className="coin--dropdown--toggle">
                              <img
                                src="assets/image/icon/arrow-down.png"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <NavLink
                    className={(login) =>
                      login.isActive
                        ? "hder--btn btn--signin active"
                        : "hder--btn btn--signin"
                    }
                    to="/login"
                  >
                    Sign in
                  </NavLink>
                  <NavLink
                    className={(register) =>
                      register.isActive
                        ? " hder--btn btn-signup active"
                        : "hder--btn btn-signup"
                    }
                    to="/register"
                  >
                    Sign up
                  </NavLink>
                </>
              )}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
