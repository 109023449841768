import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// import { store } from "./app/store";
import store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

// router
import { BrowserRouter } from "react-router-dom"; 
 

const container = document.getElementById("root");
const root = createRoot(container); 


root.render(
  // <React.StrictMode>
    <BrowserRouter>
      
        <Provider store={store}>
          <App />
        </Provider>
      
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
