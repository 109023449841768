import React from 'react';

const CardGetway = (props) => {
    return (
        <>
                        <img
                          className="kt-widget__img"
                          src={props.iconCard}
                          alt=" "
                          style={{ maxWidth: " 100%", borderRadius: "0.8rem",height: "inherit" }}
                        /> 
                        {/* <div className="amount--getway">{props.number.amount}</div> */}
                        <div className="points--getway">{props.number.amount}</div> 
                      </>
    );
}

export default CardGetway;
