import React from "react";
import { getStyle } from "../../functions.js/functions";

const RankCard2 = (props) => {
  return (
    <div className="leaderboard--user--item">
      <div className="leaderboard--user--cash">
        <img src="assets/image/icon/cashout.svg" alt="" />
        <span>{props.ran.r}</span>
      </div>
      <div className="leaderboard--user--play">
        <span>2</span>
        <img src="assets/image/icon/play-down.png" alt="" />
      </div>
      <div className="leaderboard--user--profile">
        <a
          href="#"
          className={
            "leaderboard--user--profile--pic " + getStyle(props.ran.n.charAt(0))
          }
        >
          
          <img src={'../avatars/'+props.ran.a} alt="" />
        </a>
        <div className="leaderboard--user-info">
          <p>{props.ran.n}</p>
          <span>{props.ran.score_cur} Coins</span>
        </div>
      </div>
      <div className="leaderboard--user--bottom">
        <img src="assets/image/img/money-savings.png" alt="" />
      </div>
    </div>
  );
};

export default RankCard2;
