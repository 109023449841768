import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getRef } from "../network/userNet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../redux/actions/userActions";
import { checkIp } from "../network/earnNet";

const RewardsPage = () => {
  const [ref, setRef] = useState(0);
  const [refNewUser, setRefNewUser] = useState(0);
  const [countRef, setCountRef] = useState(0);
  const [points, setpoints] = useState(0); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userReducer.userId);
  useEffect(() => {
    if (!userId) {
      navigate("/login");
    }

    getRef().then(function (response) {
      if (response.data.status === -1) {
       
        dispatch(setLogout());
      } 
      setRef(response.data.ref);
      setRefNewUser(response.data.user);
      setpoints(response.data.points);
      setCountRef(response.data.countRef);
      if(response.data.use_ip_used){  
        setTimeout(() => {
          checkIp()
        }, response.data.timer * 1000);
      }
    });
  }, []);

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    notify("copied !");
  };
  const notify = (msg) => toast(msg);
 
  return (
    <div className="register--wrap">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container">
        <div className="rewards--row  ">
          <div className="row_rewards" style={{ display: "block"}}>
          <h5>
                your frind gets
                <span> {refNewUser} </span> Coins on sign up
              </h5>

              <h5>
                and you get 
                <span> {ref} </span> Coins every time
              </h5>
          </div>
          <div className="row_rewards">
            <div className="box_reward  ">
              
            <div>
              {countRef} <br />
              Users referred
              </div>

              
            </div>
            <div className="box_reward  ">
            
              <div>
              {points} <br />
              Referral earnings
              </div>
            </div>
            
            <div className="box_reward_half  ">
             
              <div
                style={{ padding: "10px", border: "1px solid ", margin: "5px" }}
              >
                {userId}
              </div>
              <button
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#f57059",
                  color: "#fff",
                  margin: "5px",
                }}
                onClick={() => copy(userId)}
              >
                copy code
              </button>
              <button
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#f57059",
                  color: "#fff",
                  margin: "5px",
                }}
                onClick={() =>
                  copy("https://cashly.com/register?ref=" + userId)
                }
              >
                copy Link
              </button>
            </div> 
          </div>  
          </div>  
      </div>
    </div>
  );
};

export default RewardsPage;
