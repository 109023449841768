import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getGateways, postGateways } from "../network/cashout";
import Modal from "react-modal";
import { setLogout } from "../redux/actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { customStylesEmail } from "../functions.js/vars";
import ModalEmail from "../components/ModalEmail";
import { getMsg } from "../functions.js/functions";
import HeadGetWay from "../components/HeadGetWay";
import CardGetway from "../components/CardGetway";
import Lodaing from "../components/Lodaing";
import { checkIp } from "../network/earnNet";
function GetewayPage() {
  const [nameParams] = useSearchParams();
  const userId = useSelector((state) => state.userReducer.userId);
  const id = nameParams.get("name"); // "testCode"
  const [cards, setCards] = useState([]);
  const [selected, setSelected] = useState(0);
  const [lodding, setLodding] = useState(true);
  const [account, setAccount] = useState("");
  const [iconCard, setIconCard] = useState("");
  const [price, setPrice] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [msgState, setMsgState] = useState("");
  const [msgMethod, setMsgMethod] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notSelected, setNotSelected] = useState("paypal--checkbox--item  ");
  const [isSelected, setIsSelected] = useState(
    "paypal--checkbox--item active shadow-getwaye"
  );

  const notify = (msg) => toast(msg);

  function postGift() {
    setLodding(true);
    if ((account !== "") & (selected !== 0)) {
      const data = {
        wid: selected,
        acc: account,
        cc: "1",
      };
      postGateways(data).then(function (response) { 
        //  const dispatch = useDispatch();
        if (response.data.status === -1) { 
          dispatch(setLogout());
        }
        closeModal();
        if (response.data.status === 1) {
          notify("done");
        } else {
          notify(response.data.message);
        }
        //  setIsOpen(false);
        if(response.data.use_ip_used){  
          setTimeout(() => {
            checkIp()
          }, response.data.timer * 1000);
        }
        setLodding(false);
      });
    } else {
      notify("you should select card and write email ");

      setLodding(false);
    }
  }
  function selectCard(id) {
    setSelected(id);
    cards.map((number) => (number.id === id ? setPrice(number["points"]) : ""));
  }
  useEffect(() => {
    if (!userId) {
      navigate("/login");
    }

    ///////////////////
    setMsgMethod(getMsg(id));

    /////////////////
    getGateways().then(function (response) {
      if (response.data.status === -1) { 
        dispatch(setLogout());
      }
      setLodding(true);
      const result = response.data.cat.find(({ name }) => name === id);
      setCards((s) => result["items"]);
      setIconCard((s) => result["image"]); 
      setLodding(false);
    });

    // setCards(result.items)
  }, []);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    setMsgState("");
  }
  function showFrame(item) {
    setIsOpen(true);
    setMsgState("");
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesEmail}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <ModalEmail
          closeModal={closeModal}
          account={account}
          setAccount={setAccount}
          postGift={postGift}
          lodding={lodding}
          msgState={msgState}
        />
      </Modal>

      <div className="paypal--cashout--wrap">
        <div className="container">
          <div className="cashout--container--wrap">
            <HeadGetWay id={id} msgMethod={msgMethod} />

            <div className="row paypal--checkbox--row">
              {lodding ? (
                <Lodaing />
              ) : (
                cards.map((number, index) => (
                  <div
                    key={index}
                    className={
                      selected === number.id ? isSelected : notSelected
                    }
                    onClick={() => selectCard(number.id)}
                    style={{ marginBottom: "25px" }}
                  >
                    {number.quantity > 0 ? (
                      <CardGetway iconCard={iconCard} number={number} />
                    ) : (
                      ""
                    )}
                  </div>
                ))
              )}
            </div>

            <div className="cashout--bottom">
              <div className="cashout--border--bottom">
                <img
                  src="assets/image/shape/cashout-bottom.png"
                  alt=""
                  className="img--fluid"
                />
              </div>

              <div className="row justify--between cashout--bottom--row">
                <div className="cashout--bottom--left">
                  <div className="cashout--coin--box">
                    <div className="cashout--coin--label">
                      <img src="assets/image/icon/cashout.svg" alt="" />
                      <p>Coin price</p>
                    </div>
                    <div>
                      <p>{price} Coins</p>
                    </div>
                  </div>
                </div>

                <div className="cashout--bottom--right">
                  <a
                    href="#"
                    onClick={showFrame}
                    className="btn btn--Withdraw btn--more"
                  >
                    Withdraw
                    <span>
                      <img
                        src="assets/image/icon/arrow-right.svg"
                        alt="see more"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetewayPage;
