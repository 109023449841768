import React from "react";

const HeadContainer = (props) => {
  return (
    <div className="earn--heading">
      <h3>{props.title}</h3>
      <p>{props.body}</p>
    </div>
  );
};

export default HeadContainer;
