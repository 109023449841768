import React from "react";

const Section2 = (props) => {
  return (
    <>
      <div className="user--cashout--summary">
        <div className="container">
          <div className="row align--center   user--cashout--row">
            <div className="user--cashout--item user--cashout--item--start">
              <div className="user--cashout-icon">
              <img
                src="assets/image/icon/users.svg"
                alt=""
                width="30"
                className="filter-green"
              />
              </div>
              <div>
                <span>$ {props.average}</span>
                <p>
                  Average money earned  
                  by users yesterday
                </p>
              </div>
            </div>

            <div className="user--cashout--item user--cashout--item--middle">
              <div className="user--cashout-icon" style={{ marginTop: "-13px" }}>
                <img src="assets/image/icon/earn1.svg" alt="" />
              </div>
              <div>
                <span> {props.allUsers}</span>
                <p>Number of active users</p>
              </div>
            </div>

            <div className="user--cashout--item user--cashout--item--last">
              <div className="user--cashout-icon">
                <img src="assets/image/icon/money.svg" alt="" />
              </div>
              <div>
                <span>$ {props.allEarn}</span>
                <p>
                  Total USD earned on <br />
                  CASHLY
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section2;
