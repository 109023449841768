import React from 'react';

const Section5 = () => {
    return (
        <div className="faq--wrp">
        <div className="container">
          <div className="row justify--between">
            <div className="faq--left">
              <h3>common questions</h3>
              <p>
                Here you will find the most frequently <br />
                asked questions
              </p>
              <img
                src="assets/image/img/faq.png"
                alt=""
                className="img--fluid"
              />
            </div>

            <div className="faq--right">
              <div className="accordion">
                <div className="accordion--header">
                  <span className="accordion--toggle"></span>
                  <h2> What is CASHLY.PRO?</h2>
                </div>
                <div className="accordion--body">
                  <p>
                  cashly.pro is a GPT (Get-Paid-To) site that partners with some of the best offerwall and market research companies out there to provide you with the best offers and surveys that can be done in your spare time to earn coins. These coins can be used towards gift cards, CS:GO skins, cryptocurrencies, and much more.
                  </p>
                </div>
                <div className="accordion--header">
                  <span className="accordion--toggle"></span>
                  <h2>What are coins? How do I redeem them?</h2>
                </div>
                <div className="accordion--body ">
                  <p>
                  Coins are the virtual currency of cashly.pro, used to represent your current balance on the site. 1000 coins is equal to $1.00 US Dollar. Once you have earned enough coins, you can redeem them for an item of your choice through the shop!
                  </p>
                </div>
                <div className="accordion--header">
                  <span className="accordion--toggle"></span>
                  <h2>How do I get started?</h2>
                </div>
                <div className="accordion--body">
                  <p>
                  Signing up is simple! You have the choice of logging in using your Steam and Google accounts. Alternatively, you can just sign up for cashly.pro using your email. As soon as you’ve signed up, head over to our Earn page, where you can find a wide variety of offerwalls to complete offers from.
                  </p>
                </div>
                <div className="accordion--header">
                  <span className="accordion--toggle"></span>
                  <h2>Do you own all the offerwalls on this site?</h2>
                </div>
                <div className="accordion--body">
                  <p>
                  No, however, we partnered with many of the biggest offerwall companies to ensure you get the best offers to complete possible. cashly serves as a middleman - the gateway to all the best offers out there for you to earn from.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Section5;
