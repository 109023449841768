import { useState } from "react";

export function useModalHang(){
    const [modalIsOpenHang, setIsOpenHang] =  useState(false);
     
      function closeModalHang() {
        setIsOpenHang(false);
      }

    return {
        modalIsOpenHang , closeModalHang ,setIsOpenHang
    }
}