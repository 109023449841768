import React from 'react';

const Section6 = () => {
    return (
        <div className="testimonials--wrap">
        <div className="container">
          <div className="testimonials--heading text--center">
            <h3>What our Customers say</h3>
            <p>All the Lorem Ipsum generators on the Internet tend to</p>
          </div>

          <div className="owl-carousel testimonials--slider" >
        
          <div className="slide--item">
              <div className="testimonial--author">
                <img src="assets/image/img/customer1.png" alt="" />
                <div className="comas">
                  <img src="assets/image/img/comas.png" alt="" />
                </div>
              </div>
              <blockquote>
              Awesome Experience
            I feel that the app is open and honest as well as the payout methods.<br /> 
            I would prefer that all payout platforms offered an option to cashout all<br /> 
             earnings and not just specific amounts.
              </blockquote>
              <div className="star--5">
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
              </div>
              <hr className="testimonial--hr" />
              <h6 className="testimonial--name">Mike taylor</h6>
              <span className="testimonial--location">Lahore, Pakistan</span>
            </div>

            <div className="slide--item  owl-stage-outer">
              <div className="testimonial--author">
                <img src="assets/image/img/customer2.png" alt="" />
                <div className="comas">
                  <img src="assets/image/img/comas.png" alt="" />
                </div>
              </div>
              <blockquote>
              This app is legit and there is no waiting period like some other <br />
              apps/websites that make you wait months for your money. <br />
              By far the best one with live chat with support 24/7
              </blockquote>
              <div className="star--5">
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
              </div>
              <hr className="testimonial--hr" />
              <h6 className="testimonial--name">Mike taylor</h6>
              <span className="testimonial--location">Lahore, Pakistan</span>
            </div>

            <div className="slide--item  owl-stage-outer">
              <div className="testimonial--author">
                <img src="assets/image/img/customer3.png" alt="" />
                <div className="comas">
                  <img src="assets/image/img/comas.png" alt="" />
                </div>
              </div>
              <blockquote>
                Offended no concerns. Supply <br />
                worthy warmth branch of no ye.
              </blockquote>
              <div className="star--5">
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/star_gray.svg" alt="" />
                <img src="assets/image/icon/star_gray.svg" alt="" />
              </div>
              <hr className="testimonial--hr" />
              <h6 className="testimonial--name">Mike taylor</h6>
              <span className="testimonial--location">Lahore, Pakistan</span>
            </div>

            <div className="slide--item  owl-stage-outer">
              <div className="testimonial--author">
                <img src="assets/image/img/customer2.png" alt="" />
                <div className="comas">
                  <img src="assets/image/img/comas.png" alt="" />
                </div>
              </div>
              <blockquote>
              Probably the best app ever, rewards are processed immediately, <br />
             cashing out is easy and unbelievablely fast 
              </blockquote>
              <div className="star--5">
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
                <img src="assets/image/icon/Star.svg" alt="" />
              </div>
              <hr className="testimonial--hr" />
              <h6 className="testimonial--name">Mike taylor</h6>
              <span className="testimonial--location">Lahore, Pakistan</span>
            </div>
          </div>

          <div className="testimonial--slider--btn">
            <button className="slider--btn btn--left customPreviousBtn">
              <img src="assets/image/icon/slide-lft.svg" alt="" />
            </button>
            <button className="slider--btn btn--right customNextBtn">
              <img src="assets/image/icon/slide-rit.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    );
}

export default Section6;
