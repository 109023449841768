import React from 'react';
import { financialFloat } from '../functions.js/functions';

const CardOfferApi = ({  offerApi ,showModalApi}) => { 
      return  offerApi.map((item, index) => (
        <a href="#" key={index} onClick={() => showModalApi(item)} className="featured--item">
              <img
                src={item.image_url_220x124}
                alt=""
                className="earn--overlay--bg"
              />
              <div className="featured--overlay">
                <div className="featured--overlay--content">
                  <span className="titleApiCard">{item.offer_name.slice(0, 5)}  </span>
                  <button className="featured-survey-amount">{financialFloat(item.payout)}$</button>
                </div>
                <div className="text-ellipsis">
                  <p>{item.call_to_action.slice(0, 10)}...</p>
                </div>
              </div>
            </a>
    ) );
}

export default CardOfferApi;
