import { ActionTypes } from "../constants/action-types"

export const setLogin = (dataUser) => {
    return {
        type : ActionTypes.LOGIN,
        payload : dataUser
    };
};
 
export const setLogout = () => { 
    return { 
        type : ActionTypes.LOGOUT,
        payload : []
    };
};
 
export const newBalanceAction = (newBalance) => { 
    return { 
        type : ActionTypes.NEW_BALANCE,
        payload : newBalance
    };
};
 

export const setUpdateImg = (newIMG) => { 
    return { 
        type : ActionTypes.UPDATE_IMG,
        payload : newIMG
    };
};
 