import React from "react";

const Lodaing = () => {
  return (
    <div className="margin_load">
      <div className="load"></div>
    </div>
  );
};

export default Lodaing;
