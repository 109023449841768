 
import {  axiosClientAuth } from "./base";

export function getProfile() {
  return axiosClientAuth.post("profile");
}
 
export function updateProfile(data) {
  return axiosClientAuth.post("profile",data);
} 

export function updatePassword(data) {
  return axiosClientAuth.post("profile/update",data);
} 

export function updateImg(data) {
  return axiosClientAuth.post("profile/update/img",data);
} 

export function sendCodeAgain() {
  return axiosClientAuth.post("sendCodeAgain");
} 

export function getRef() {
  return axiosClientAuth.post("ref");
} 

export function getChat() {
  return axiosClientAuth.post("chat/get");
} 

 
export function postChat(data) {
  return axiosClientAuth.post("chat/post",data);
} 

export function getChatSupport() {
  return axiosClientAuth.post("support/get");
} 
export function postChatSupport(data) {
  return axiosClientAuth.post("support/post",data);
} 