import { useState } from "react";

export function useModalApi(){
    const [modalIsOpenApi, setIsOpenApi] =  useState(false);
     
      function closeModalApi() {
        setIsOpenApi(false);
      }

    // const changeCode = (e)=>{
    //     const note = e.target.value;
    //     setCode(note); 
    // }
    return {
        modalIsOpenApi , closeModalApi,setIsOpenApi 
    }
}