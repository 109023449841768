import React from 'react';

const RankTable = (props) => {
    return (
        <table className="table leaderboard--table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Username</th>
            <th>Coins</th>
            <th>Prize</th>
          </tr>
        </thead>
        <tbody>
           
          {props.rank.map((ran,index) =>  
          index > 2 ? 
          <tr data-expanded="true"  key={index}>
            <td>
              <button className="tb--rank--btn">{index + 1 }</button>
            </td>
            <td>
              <p>{ran.n}</p>
            </td>
            <td>
              <span>{ran.score_cur}</span>
            </td>
            <td>
              <p>{ran.r} Coins</p>
            </td>
          </tr>
          : "" 
           )}
         
        </tbody>
      </table>
    );
}

export default RankTable;
