import React from 'react';
import { Link } from 'react-router-dom';

const ImageCashout = (props) => {
    return (
        <>
             <Link
                    key={props.index}
                    to={"/geteway?name=" + props.item.name}
                    className="withdraw--cash--item "
                  >
                    <img
                      src={props.item.image}
                      alt=""
                      style={{
                        width: "17rem",
                        borderRadius: "10px",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    />
                  </Link> 
        </>
    );
}

export default ImageCashout;
