import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const ConfirmPage = () => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(true);
  function postGift(code) {
    const url = "https://cashly.cashly.pro/api/checkCode/m/" + code;
    return axios.get(url).then(function (response) {
        if(response.data.status === 1){
          setStatus(true);
        }else{
          setStatus(false);

        }
      setMessage(response.data.message);
    });
  }

  useEffect(() => {
    let code = new URLSearchParams(window.location.search).get("code");

    postGift(code);
  }, []);
  return (
    <div className="register--wrap" style={{ minHeight: "40vh", marginTop:"50px" }}>
      <div className="container-2">
        <div className="card-2">
          <div className="text-2">
            <div className={status ? "alert alert-success" :"alert alert-danger"} >{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPage;
