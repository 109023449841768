import React from "react";
import { getStyle } from "../../functions.js/functions";

const RankCard1 = (props) => {
  return (
    <div className="leaderboard--user--item leaderboard--user--item--middle">
      <div className="leaderboard--user--cash">
         
        <span>{props.ran.r} Coins</span>
      </div>
      <div className="leaderboard--user--play">
        <span>1</span>
        <img src="assets/image/icon/crown.png" alt="" />
      </div>
      <div className="leaderboard--user--profile">
      <img src={'../avatars/'+props.ran.avatar} alt="" />
        <a
          href="#"
          className={
            "leaderboard--user--profile--pic " + getStyle(props.ran.n.charAt(0))
          }
        >
           <img src={'../avatars/'+props.ran.a} alt="" />
        </a>
        <div className="leaderboard--user-info">
          <p>{props.ran.n}</p>
          <span>{props.ran.score_cur} Coins</span>
        </div>
      </div>
      <div className="leaderboard--user--bottom">
        <img src="assets/image/img/money-management.png" alt="" />
      </div>
    </div>
  );
};

export default RankCard1;
