import React from 'react';

const HourRank = () => {
    return (
        <div className="leaderboard--rank--time">
        <p className="time--end--in">Ends in</p>
        <div className="leaderboard--get--time">
          <p>
            <span>12</span> H
          </p>
          <p>
            <span>00</span> M
          </p>
          <p>
            <span>00</span> S

          PM
          </p>
        </div>
      </div>
    );
}

export default HourRank;
