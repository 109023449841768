import React from "react";
import HeadContainer from "./HeadContainer";
import CardOffer from "../components/CardOffer";
import Lodaing from "./Lodaing";
import CardOfferOgads from "./CardOfferOgads";

const OfferWall = (props) => {
  return (
    <div className="offer--walls--wrap">
      <div className="container position--relative">
        <div className="offer--walls--bg">
          <img src="assets/image/shape/task-line-bg.png" alt="" />
        </div>
        {props.type === "surveys" ? (
          <HeadContainer
            title="Surveys"
            body="Complete surveys to earn coins. You will be rewarded for each
                survey completed."
          />
        ) : (
          <HeadContainer
            title="Offer walls"
            body="Each offer wall contains hundreds of tasks to complete."
          />
        )}
        {props.loading ? (
         <Lodaing />
        ) : (
          <div className="row offer--wall--row">
            {props.offerwalls !== [] ? (
              <CardOffer
                offer={props.offerwalls}
                handleShow={props.showFrame}
              />
            ) : (
              <></>
            )}
        {props.ogadsApi ?
          <CardOfferOgads 
            handleShowOgads={props.handleShowOgads}
            img="assets/image/ogads.png"
          /> 
          :<></>
          } 
{props.run_youmi ?
          <CardOfferOgads 
            handleShowOgads={props.handleShowYoumi}
            img={props.img_youmi}
          /> 
          :<></>
          } 
{props.run_hang ?
          <CardOfferOgads 
            handleShowOgads={props.handleShowHang}
            img={props.img_hang}
          /> 
          :<></>
          } 
          
          </div>
        )}

        
      </div>{" "}
    </div>
  );
};

export default OfferWall;
