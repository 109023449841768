import React from "react";
import { NavLink } from "react-router-dom";

function MobileNav() {
  return (
    <>
      <ul className="mobile--navigation">
       
      <NavLink
          className={(Rewards) =>
            Rewards.isActive ? " active" : " "
          }
          to="/rewards"
        >
          <img src="assets/image/icon/rewards.png" alt="Rewards" />
          <span>Rewards</span>
        </NavLink>

        <NavLink
          className={(leaderboard) =>
            leaderboard.isActive ? " active" : " "
          }
          to="/leaderboard"
        > 
          <img src="assets/image/icon/leader_board.svg" alt="Rewards" />
          <span>leaderboard</span>
        </NavLink>

        <NavLink
          className={(Earn) =>
            Earn.isActive ? "mobile--middle--itm " : "mobile--middle--itm notActive"
          }
          to="/earn"
        >
          <img src="assets/image/icon/earn.svg" alt="earn" />
          <span>Earn</span>
        </NavLink>

        <NavLink
          className={(Cashout) =>
            Cashout.isActive ? " active" : " "
          }
          to="/cashout"
        >
          <img src="assets/image/icon/cashout.svg" alt="Rewards" />
          <span>Cashout</span>
        </NavLink>
        <a href="#" className="mobile--chat--btn">
        <img src="assets/image/icon/chat.png" alt="" />
        <span>Chat</span>
      </a>
      </ul>
    </>
  );
}

export default MobileNav;
