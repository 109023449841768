import React from "react";
import { NavLink } from "react-router-dom";
  
function Footer() {  
     
    return (<>
    <footer className="foo--wrp position--relative">
      <div className="footer--shape--1">
        <img src="assets/image/shape/body-shape-1.png" alt="" />
      </div>
      <div className="footer--shape--2">
        <img src="assets/image/shape/body-shape-2.png" alt="" />
      </div>
     
      <div className="container">
      
        <div className="row justify--between foo--top">
           
          <div className="foo--item foo--item--1">
            
            <a href="#" className="logo d--flex align--center foo--logo">
              <img src="assets/image/icon/logo16.png" alt="logo" />
              <span>CASHLY</span>
            </a>
            
            <p>
              Really boy law county she unable her <br />
              sister. Feet you off its like like six.
            </p>
           
            <ul className="foo--social">
              <li>
                <a href="#"
                  ><img src="assets/image/icon/facebook.svg" alt=""
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img src="assets/image/icon/twitter.svg" alt=""
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img src="assets/image/icon/linkedin.svg" alt=""
                /></a>
              </li>
            </ul>
           
          </div>
         
 
          <div className="foo--item foo--item--2">
            <h5>About</h5>
            <ul>
           <li> <NavLink className={(login)=>login.isActive ? ' active' : ' '}   to="/terms_of_service" > 
            Terms of service </NavLink></li>

            <li><NavLink className={(login)=>login.isActive ? ' active' : ' '}   to="/privacy_policy" > 
            Privacy policy </NavLink></li>
              
            </ul>
          </div>
         
          <div className="foo--item foo--item--3">
            <h5>Support</h5>
            <ul>
              <li><a href="#">FAQ</a></li>
              <li><a href="#">Contact</a></li>
            </ul>
          </div>
          
          <div className="foo--item foo--item--4 discover--app">
            <h5>Discover our app</h5>
            <ul>
              <li>
                <a href="#">
                  <div>
                    <img src="assets/image/brand/google-play.png" alt="" />
                  </div>
                  <div>
                    <span>GET IT</span>
                    <span>GOOGLE PLAY</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div><img src="assets/image/brand/apple.png" alt="" /></div>
                  <div>
                    <span>Avalible on</span>
                    <span>Apple</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
         
        </div>
        
        <div className="row d--flex align--center justify--between foo--bottom">
          <p>All rights Cashly</p>
          <ul className="language--list">
            <li>Language</li>
            <li className="active">En</li>
            <li>Ar</li>
          </ul>
        </div>
        
      </div>
    
    </footer>
    </>)
}

export default Footer;