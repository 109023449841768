import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { decode } from "../functions.js/functions";
import { setLogin } from "../redux/actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterRight from "../components/RegisterRight";
function LoginPage() {
  const logged = useSelector((state) => state.userReducer.logged);
  const token = useSelector((state) => state.userReducer.token);
  const userId = useSelector((state) => state.userReducer.userId);
  const dispatch = useDispatch();
  const [msgError, setMsgError] = useState("");
  const [typePassword, setTypePassword] = useState("password");

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (userId) {
      navigate("/earn");
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const article = { email: email, password: password, did: 1 };
    const response = await axios
      .post("https://cashly.cashly.pro/api/login", article)
      .then((response) => {
        response.data["data"] = JSON.parse(decode(response.data["data"]));

        if (response.data["data"]["status"] === 1) {
          dispatch(setLogin(response.data["data"]));
          window.location.replace("/");
        } else {
          notify(response.data["data"]["message"]);
          setMsgError(response.data["data"]["message"]);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err.response.data.message);
        setLoading(false);
      });
  };
  const notify = (msg) => toast(msg);
  const changeTypePassword = () => {
    if (typePassword === "password") {
      setTypePassword("text");
    } else {
      setTypePassword("password");
    }
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="register--wrap">
        <div className="container">
          <div className="row register--row justify--between">
            <div className="register--content register--left">
              <h3>Sign in</h3>
              <small>
                Hey, Enter your details to get sign in <br />
                to your account.
              </small>

              <Form
                onSubmit={handleSubmit}
                className="register--form"
                name="register"
              >
                <div className="from--control">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="your_email@gmail.com"
                  />
                </div>

                <div className="from--control position--relative">
                  <input
                    type={typePassword}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <div className="solid--eye" onClick={changeTypePassword}>
                    <img src="assets/image/icon/eye-solid.png" alt="" />
                  </div>
                </div>
                <p className="register--p1 forgot--p">
                  <a href="#">Forgot your password?</a>
                </p>

                <button type="submit" className="submit" disabled={loading}>
                  <span
                    className={loading ? "loadingdiv spinner" : "loadingdiv"}
                  ></span>
                  <span>Login</span>
                </button>
              </Form>
              <p className="text--center">
                Don't have an account?
                <NavLink to="/register">Sign up</NavLink>
              </p>
            </div>

            <RegisterRight />
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
