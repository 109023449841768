import { useState } from "react";

export function useModalOgads(){
    const [modalIsOpenOgads, setIsOpenOgads] =  useState(false);
     
      function closeModalOgads() {
        setIsOpenOgads(false);
      }

    return {
        modalIsOpenOgads , closeModalOgads ,setIsOpenOgads
    }
}