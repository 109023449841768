import React from "react";

const CardOffer = ({ offer, handleShow }) => {
  return offer.map((item, index) => (
    <span
      
      key={index}
      onClick={() => handleShow(item)}
      className="offer--wall--item"
    >
      <img src={item.image} alt="" />
      <button className="bonus--btn">+10% Bonus</button>
    </span>
  ));
};

export default CardOffer;
