import axios from "axios";

const axiosClient = axios.create({
  baseURL: `https://cashly.cashly.pro/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const itemSet = (localStorage.getItem('token') !== null);

let token ="";
if (itemSet)  {

  token = window.localStorage.getItem("token");
} 
  // console.log(token);
const axiosClientAuth = axios.create({
  baseURL: `https://cashly.cashly.pro/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  },
});

export { axiosClient, axiosClientAuth };
