import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Section1 from "../components/homePage/Section1";
import Section2 from "../components/homePage/Section2";
import Section3 from "../components/homePage/Section3";
import Section4 from "../components/homePage/Section4";
import Section5 from "../components/homePage/Section5";
import Section6 from "../components/homePage/Section6";
import { checkIp } from "../network/earnNet";
import { getDetailsHome } from "../network/notUser";
import { setLogout } from "../redux/actions/userActions";
function HomePage() {
  const [allEarn, setAllEarn] = useState(0);
  const [allUsers, setAllUsers] = useState(0);
  const [average, setAverage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getDetailsHome().then(function (response) {
      if (response.data.status === -1) { 
        dispatch(setLogout());
      } 
      setAllUsers(response.data.data.users);
      setAllEarn(response.data.data.earns);
      setAverage(response.data.data.average);
      if(response.data.data.use_ip_used){
         setTimeout(() => {
          checkIp()
        }, response.data.data.timer * 1000);
      }
    });
  }, []);
  return (
    <>
      <Section1 />
      <Section2 allUsers={allUsers} allEarn={allEarn} average={average} />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </>
  );
}

export default HomePage;
