import axios from "axios";
import { axiosClient, axiosClientAuth } from "./base";

export function getEarn() {
  return axiosClientAuth.post("page/earn");
}
export function getoffers() { 
  return axios.get(
     'https://www.offertoro.com/api/?pubid=26750&appid=11647&secretkey=2e0eda233c5cda00d96306646a9baa4e&country=&platform=all&format=json'
  ); 
}
export function addProduct(data) {
  return axiosClientAuth.post("/product", JSON.stringify(data));
}

export function checkIp() { 
  return axiosClientAuth.post("/checkIp");
}
