export function saltKey() {
  var count = 0;
  var i = 0;
  var sKey = "PyV1ZfWeaxQw";
  for (i = 0; i < sKey.length; i++) {
    count += sKey[i].charCodeAt(0);
  }
  return count;
}

export function decode(str) {
  var data = "";
  var salt = saltKey();
  var arrayData = str.split("x");
  var i = 0;
  for (i = 0; i < arrayData.length; i++) {
    data += chr(arrayData[i] - salt - i);
  }
  return data;
}

function chr(n) {
  if (n < 128) {
    return String.fromCharCode(n);
  } else {
    return "ÇüéâäàåçêëèïîìÄÅÉæÆôöòûùÿÖÜ¢£¥₧ƒáíóúñÑªº¿⌐¬½¼¡«»░▒▓│┤╡╢╖╕╣║╗╝╜╛┐└┴┬├─┼╞╟╚╔╩╦╠═╬╧╨╤╥╙╘╒╓╫╪┘┌█▄▌▐▀αßΓπΣσµτΦΘΩδ∞φε∩≡±≥≤⌠⌡÷≈°∙·√ⁿ²■ "[
      n - 128
    ];
  }
}
export function encodeFunc(str) {
  var data = "";
  var salt = saltKey();
  var padding = 0;
  var i = 0;
  for (i = 0; i < str.length; i++) {
    data += ord(str[i]) + salt + padding + "x";

    padding += 1;
  }
  return data.slice(0, -1);
}
function ord(str) {
  return str.charCodeAt(0);
}

export function getMsg(id) {
  switch (id) {
    case "Paypal":
      return "Please note that PayPal withdrawals have a flat fee of 5%.   After withdrawing claim your PayPal Cashout on your profile.";

    case "googleplay":
      return "Google Play Gift Cards can be used to buy games, apps, films & much more. ";

    case "bitcoin":
      return "Crypto withdrawals take a few minutes to be sent.  New users have to earn $2.00 in order to make their first withdrawal . ";

    case "ethereum":
      return "Crypto withdrawals take a few minutes to be sent.   New users have to earn $2.00 in order to make their first withdrawal.";

    case "litecoin":
      return "Crypto withdrawals take a few minutes to be sent.   New users have to earn $2.00 in order to make their first withdrawal.";

    case "ach":
      return "Please note that Bank Transfer withdrawals have a flat fee of 5%.  After withdrawing claim your Bank Transfer on your profile.";

    case "amazon":
      return "You will receive an Amazon gift card for your selected country, converted from USD to your local currency.";

    case "Visa":
      return " Please note that this is a prepaid card which can be used to shop online. After withdrawing claim your Visa Card on your profile.";

    case "Pubg":
      return "message for pubg";
     

    case "FreeFire":
      return "message for Free Fire";

    default:
      return "msgs";
  }
}
function isInArray(value, array) {
  return array.indexOf(value) > -1;
}
export function getStyle(key) {
  const array1 = ["a", "h", "p", "w", "A", "H", "P", "W"];
  const array2 = ["b", "j", "q", "x", "B", "J", "Q", "X"];
  const array3 = ["c", "k", "r", "y", "C", "K", "R", "Y"];
  const array4 = ["d", "l", "s", "z", "D", "L", "S"];
  const array5 = ["e", "m", "t", "E", "M", "T"];
  const array6 = ["f", "n", "u", "F", "N", "U"];
  const array7 = ["g", "o", "v", "G", "O", "V"];
  if (isInArray(key, array1)) return "a1";
  if (isInArray(key, array2)) return "a2";
  if (isInArray(key, array3)) return "a3";
  if (isInArray(key, array4)) return "a4";
  if (isInArray(key, array5)) return "a5";
  if (isInArray(key, array6)) return "a6";
  if (isInArray(key, array7)) return "a7";
}

export function financialFloat(x) {  
  const number = Number.parseFloat(x).toFixed(2); 
  return number
 }
 