import React from 'react';

const TermsPage = () => {
    return (
        <div>
            <div className="container">
        <div className="policy--service--container">
          <div className="policy--srvice--heading">
            <h3>Terms of service</h3>
            <div className="policy--service--date">LAST UPDATED - 2021-07-31</div>
          </div>
          <div className="policy--service--content">
            <h4>1.0 - Terms</h4>
            <p>
              By accessing our services on this site, you are agreeing to the
              Terms and Conditions laid out here. You also agree to comply with
              all applicable laws and regulations, and you are responsible for
              compliance with any local laws. This site is protected by
              applicable copyright law. We reserve the right to refuse service
              to anyone, without reason at any time.
            </p>
            <h4>2.0 - Prohibited uses</h4>
            <p>This site may not be used for unlawful purposes.</p>
            <h4>3.0 - On-site chat</h4>
            <p>
              The chat feature on the site may not be used for the following:
            </p>
            <ul>
              <li>- Harassing other users or making them feel unwelcome.</li>
              <li>
                - Toxicity, or saying things devised to provoke a reaction onto
                others.
              </li>
              <li>
                - Advertising or spamming. This includes posting referral links.
              </li>
              <li>- Asking other users or staff for money (begging).</li>
              <li>- Sharing pornographic or deep web links.</li>
            </ul>
            <h4>4.0 - Restricted access</h4>
            <p>You must be at least 13 years of age to use our services.</p>
            <h4>5.0 - Referral link/Promo code</h4>
            <p>
              We reserve the right to revoke users' referral link/promo code.
              This would only be done in the case of a user taking a code
              commonly used by another person, such as YouTubers or online
              celebrities.
            </p>
            <h4>6.0 - Offer holds</h4>
            <p>
              We reserve the right to temporarily hold offer credits at any
              time. This will occur automatically if a survey goes over a user's
              credit limit. <br />
              We reserve the right to hold these credits for up to 90 days after
              offer completion.
            </p>
          </div>
        </div>
      </div>
        </div>
    );
}

export default TermsPage;
