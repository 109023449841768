import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getGateways } from "../network/cashout"; 
import { useDispatch, useSelector } from "react-redux";
import { setGateways } from "../redux/actions/chashoutAction";
import { setLogout } from "../redux/actions/userActions";
import { cardArray, cashArray, gameArray } from "../functions.js/vars"; 
import ImageCashout from "../components/ImageCashout";
import Lodaing from "../components/Lodaing";
import { checkIp } from "../network/earnNet";
 

function CashoutPage() {
  // const [gateways, setGateways] = useState([]);
  const gateways2 = useSelector((state) => state.getewaysReducer.geteways);
  const dispatch = useDispatch();
  const [lodding, setLodding] = useState(true);
  
  useEffect(() => {
    getGateways().then(function (response) {
      //  const dispatch = useDispatch();
      console.log(response.data);
      if (response.data.status === -1) {
       
        dispatch(setLogout());
      } 
      setLodding(false);
      dispatch(setGateways(response.data.cat));
      if(response.data.use_ip_used){ 
        setTimeout(() => {
          checkIp()
        }, response.data.timer * 1000);
    }
    });
  }, []);

  const checkInArrayCash = (item) => {
    if (cashArray.indexOf(item) > -1) {
      return true;
    }
    if (cardArray.indexOf(item) === -1 && gameArray.indexOf(item) === -1) {
      return true;
    }
    return false;
  };

  const checkInArrayCard = (item) => {
    if (cardArray.indexOf(item) > -1) {
      return true;
    }
    return false;
  };

  const checkInArrayGame = (item) => {
    if (gameArray.indexOf(item) > -1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="cashout--withdraw--wrp">
        <div className="container">
          <div className="cashout--hint--heading">
            <div className="cashout--hint--top">
              <img src="assets/image/icon/cashout.svg" alt="" />
              <span>Cashout</span>
            </div>

            <div className="cashout--hint">
              <div className="cashout--hint--icon">
                <img src="assets/image/icon/hint.png" alt="" />
              </div>
              <div>
                <p>
                  Use your earned coins on CASHLY.PRO to withdraw PayPal,
                  Bitcoin,VISA, <br />
                  Amazon & much more! Crypto withdrawals start from 0.10$.
                </p>
              </div>
            </div>
          </div>

          <div className="cashout--heading">
            <h3>Withdraw cash</h3>
          </div>

          <div className="row withdraw--cash--row">
            {lodding ? (
               <Lodaing />
            ) : (
              gateways2.map((item, index) =>
                checkInArrayCash(item.name) ? (
                <ImageCashout item={item} index={index}  key={index} />
                ) : (
                  ""
                )
              )
            )}
          </div>
        </div>
      </div>

      <div className="withdraw--giftcard--wrap">
        <div className="container position--relative">
          <div className="withdraw--giftcard--bg">
            <img src="assets/image/shape/task-line-bg.png" alt="" />
          </div>

          <div className="cashout--heading">
            <h3>Withdraw giftcards</h3>
          </div>

          <div className="row withdraw--gift--row">
            {gateways2
              ? gateways2.map((item, index) =>
                  checkInArrayCard(item.name) ? (
                    <ImageCashout item={item} index={index} key={index}/> 
                  ) : (
                    <></>
                  )
                )
              : ""}
          </div>
        </div>
      </div>

      <div className="withdraw--skins--wrap">
        <div className="container position--relative">
          <div className="withdraw--skins--bg">
            <img src="assets/image/shape/header-bottom.png" alt="" />
          </div>

          <div className="cashout--heading">
            <h3>Withdraw game</h3>
          </div>

          <div className="row withdraw--skins--row">
            {gateways2
              ? gateways2.map((item, index) =>
                  checkInArrayGame(item.name) ? (
                    <ImageCashout item={item} index={index} key={index}/>  
                  ) : (
                    <></>
                  )
                )
              : ""}
          </div>
        </div>
      </div>
    </>
  );
}

export default CashoutPage;
