export var gameArray = ["Pubg", "freefire"];
export var cardArray = ["Google Play Gift Card", "Visa", "amazon"];
export  var cashArray = [
    "Paypal",
    "Payeer",
    "Payoner",
    "litecoin",
    "Ach",
    "bitcoin",
    "ethereum",
  ];

  export const customStylesIFrame = {
    content: {
      width: "98%",
      height: "90%",
      top: "5%",
      left: "0%",
      right: "auto",
      bottom: "auto",
      backgroundColor: "#191e3c",
      overflowX: "hidden",
      zIndex: "9999",
      border:"initial",
      // overflow:"scroll"
    },
  };

  export const customStylesIFrameApi = {
    content: {
         
    },
  };


  export  const customStylesEmail = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      backgroundColor: "#191e3c",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
    },
  };


  // profile