import React from 'react';

const RegisterRight = () => {
    return (
        <div className="register--right">
        <div className="register--right--top">
          <img
            src="assets/image/img/vactor-login-top.png"
            className="img--fluid"
            alt=""
          />
        </div>
        <div className="register--right--bottom">
          <img
            src="assets/image/img/vactor-login-bottom.png"
            className="img--fluid"
            alt=""
          />
        </div>
      </div>
    );
}

export default RegisterRight;
