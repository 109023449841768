import React from 'react'; 
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route } from "react-router-dom";
import EarnPage from './pages/EarnPage';
import HomePage from './pages/HomePage';
import Header from './components/Header';
import LoginPage from './pages/LoginPage';
import CashoutPage from './pages/CashoutPage';
import GetewayPage from './pages/GetewayPage';
import ProfilePage from './pages/ProfilePage';
import Footer from './components/footer';
import UpperBar from './components/upperBar';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import RegisterPage from './pages/RegisterPage';
import LeaderboardPage from './pages/LeaderboardPage';
import RewardsPage from './pages/RewardsPage';
import ConfirmPage from './pages/ConfirmPage';

function App() {
  return (
    <div className="App">
     <Header />
     <UpperBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/earn" element={<EarnPage />} />
        <Route path="/cashout" element={<CashoutPage />} />
        <Route path="/geteway" element={<GetewayPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/terms_of_service" element={<TermsPage />} />
        <Route path="/privacy_policy" element={<PrivacyPage />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/rewards" element={<RewardsPage />} />
        <Route path="/confirm" element={<ConfirmPage />} />
        
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
