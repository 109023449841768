import { useState } from "react";

export function useModal(){
    const [modalIsOpen, setIsOpen] =  useState(false);
     
      function closeModal() {
        setIsOpen(false);
      }

    // const changeCode = (e)=>{
    //     const note = e.target.value;
    //     setCode(note); 
    // }
    return {
        modalIsOpen , closeModal ,setIsOpen
    }
}