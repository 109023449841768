import { ActionTypes } from "../constants/action-types";

const userId = window.localStorage.getItem('userId') ;
const token = window.localStorage.getItem('token') ;
const name = window.localStorage.getItem('name') ;
const balance = window.localStorage.getItem('balance') ;
const points = window.localStorage.getItem('points') ; 
const email = window.localStorage.getItem('email') ;
const avatar = window.localStorage.getItem('avatar') ;
 
const initialState =  
userId
    ? { logged: true,  
        token:token ,
         userId:userId,
         name:name,
         email:email, 
         balance:balance,
         points:points,
         avatar:avatar
    }
    : { logged: false, token: null, userId: null,
        dataUser:null };
    
   
export const userReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.LOGIN:
       
          window.localStorage.setItem( 'token',payload.message );
          window.localStorage.setItem( 'logged',true );
         window.localStorage.setItem('userId',payload.u );
         window.localStorage.setItem('name',payload.dataUser.name ); 
         window.localStorage.setItem('balance',payload.dataUser.balance );
         window.localStorage.setItem('points',payload.dataUser.points );
         window.localStorage.setItem('avatar',payload.dataUser.avatar );
         window.localStorage.setItem('email',payload.dataUser.email );
            // console.log(payload.dataUser);
            return {
                ...state,  
                logged:true,
                token : payload.message,
                userId : payload.u,
                name:payload.dataUser.name,
                balance:payload.dataUser.balance,
                points:payload.dataUser.points,
                avatar:payload.dataUser.avatar,
                email:payload.dataUser.email
            };
        case ActionTypes.LOGOUT: 
        console.log("logout");
        window.localStorage.removeItem( 'token'); 
        window.localStorage.removeItem( 'userId'); 
        window.localStorage.removeItem( 'name'); 
        window.localStorage.removeItem( 'balance'); 
        window.localStorage.removeItem( 'points'); 
        window.localStorage.removeItem( 'avatar'); 
        window.localStorage.removeItem( 'email'); 
        return{
            ...state, 
            logged: false,  
            token :"",
            userId : "" ,
            name : "" ,
            balance : "" ,
            points : "" , 
            email : "" ,
            avatar : "" 
        }    

        case ActionTypes.NEW_BALANCE:
        window.localStorage.setItem( 'balance',payload); 
        // console.log("payload :" ,payload);
        return {
            ...state,
            balance:payload
        };
        
        case ActionTypes.UPDATE_IMG:
        window.localStorage.setItem( 'avatar',payload); 
        //   console.log("payload :" ,payload);
        return {
            ...state,
            avatar:payload
        };
        default:
            return state;
    };
};
 