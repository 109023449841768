import React from 'react';

const HeadGetWay = (props) => {
    return (
        <div className="row justify--between align--center cashout--top--row">
              <div className="cashout--logo">
                {/* <img src="assets/image/brand/paypalShopLogo.png" alt="" /> */}
                <h2>{props.id}</h2>
              </div>

              <div className="cashout--hint cashout--hint--payment" style={{maxWidth:"500px"}}>
                <div className="cashout--hint--icon">
                  <img src="assets/image/icon/hint.png" alt="" />
                 
                </div>
                <div>
                  <p>
                   {props.msgMethod}
                  </p>
                </div>
              </div>
            </div>
    );
}

export default HeadGetWay;
