 
import {  axiosClientAuth } from "./base";

export function getGateways() { 
  return axiosClientAuth.post("gift/getAll");
}


export function postGateways(data) {
  return axiosClientAuth.post("gift/post", data);
}


 